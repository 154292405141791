import React, { useEffect, useState } from "react"
import { trackPromise } from 'react-promise-tracker';
import { useNavigate } from "react-router-dom";
import browserSignature from 'browser-signature';

const UploadPhoto = ({setSource}) => {
    const [imgSrc, setImgSrc] = useState();
    const [loading, setLoading] = useState(true);
    const [description, setDescription] = useState();
    const signature = browserSignature();
    const navigate = useNavigate();

    useEffect(() => {
        setImgSrc(localStorage.getItem('imgSrc'));
        setLoading(false);
    }, [])



    const uploadImage = async (e) => {
        e.preventDefault();
        if (imgSrc) {
          const description = document.getElementById("description").value;
          const source = localStorage.getItem('imgSrc');

          const body = {
            image: source,
            description,
            eventId: localStorage.getItem('eventId'),
            signature: signature
          }

          console.log(body);

        //   const response = await trackPromise(fetch('http://localhost:3030/api/setImg/', {
            const response = await trackPromise(fetch('https://api.jannekedegraaff.nl/api/snapshot/setImg/', {
            method: "POST",
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
          })
          .then(() => navigate("/upload-done"))
          .catch(err => alert(err))
          )
        }

      }

      if (!loading && !imgSrc) {
        navigate('/home');
      }

    return(
        <>
        {imgSrc &&
        <div style={{width: '100%', paddingHorizontal: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
            <p onClick={() => navigate('/home')}>Terug</p>
            <img width={"60%"} src={imgSrc} alt="test"/>
            <form onSubmit={(e) => uploadImage(e)} style={{display: 'flex', flexDirection: 'column'}}>
                <p style={{margin: 0}}>Voeg een omschrijving toe</p>
                <textarea rows="4" style={{marginTop: '5px', padding: '10px', fontSize: '16px'}} id="description" name="description" />
                <button>Verstuur foto</button>
            </form>
        </div>
        }


        </>
    )


}
export default UploadPhoto