import React, { useEffect, useState} from "react";
import logo from "../logo-clean.svg"
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import EventModal from "../components/EventModal";
import { useIdleTimer } from "react-idle-timer";

const Admin = () => {

    const navigate = useNavigate();
    const [rerender, setRerender] = useState();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [userInfo, setUserInfo] = useState();
    const [eventList, setEventList] = useState([]);
    const [idleState, setIdleState] = useState('Active');
    const [count, setCount] = useState(0);
    const [remaining, setRemaining] = useState(0);

    const onIdle = () => {
        sessionStorage.removeItem('authenticated');
        sessionStorage.removeItem('user');

        setIdleState('Idle');
    };

    const onActive = () => {
        setIdleState('Active')
    };

    const onAction = () => {
        setCount(count + 1);
    }

    const { getRemainingTime } = useIdleTimer({
        onIdle,
        onActive,
        onAction,
        timeout: 300_000,
        throttle: 500
    })

    useEffect(() => {
      const interval = setInterval(() => {
        setRemaining(Math.ceil(getRemainingTime() / 1000))
      }, 500)

      return () => {
        clearInterval(interval)
      }
    })

    const getEvents = async () => {
        await fetch(`https://api.jannekedegraaff.nl/api/snapshot/admin/getEvents/${userInfo.user_id}`)
        .then((r) => r.json())
        .then((r) => {
            const events = r;
            setEventList(events);
            console.log(events);
        })
        .catch((err) => console.log(err));
    }

    useEffect(() => {
        if (sessionStorage.getItem('authenticated') === null || sessionStorage.getItem('authenticated') === false) {
            navigate('/login');
        } else if (sessionStorage.getItem('authenticated')) {
            setIsAuthenticated(true);
            const uInfo = JSON.parse(sessionStorage.getItem('user'));
            setUserInfo(uInfo);
            if (uInfo.admin === 1) {
                setIsAdmin(true);
            }
        }
    }, [rerender])

    useEffect(() => {
        if (isAuthenticated && isAdmin) {
            getEvents();
        }
    }, [isAuthenticated])

    return(
        <div className="App-background">
            <EventModal />
        <header className="App-header">
        <img onClick={() => navigate('/home')} style={{paddingTop: '1em'}} src={logo}/>
        {isAdmin ? <h4>ADMIN</h4> : <h2>NORMAL USER</h2>}
      </header>
      <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
        <div style={{backgroundColor: '#3e4148', width: '80%', borderRadius: '10px', padding: '10px', marginBottom: '20px'}}>
            <h2>Mijn evenementen</h2>
            <div style={{overflow: 'auto', borderRadius: '10px'}}>
            <table>
                <tr>
                    <th>Naam</th>
                    <th>Omschrijving</th>
                    <th style={{textAlign: 'center'}} >Wachtwoord</th>
                </tr>
                <tbody>
                    {eventList.map((event, key) => {
                        return (
                        <tr>
                            <td>{event.event_name}</td>
                            <td>{event.event_description}</td>
                            {event.event_hasPassword ?  <td style={{textAlign: 'center'}}><FontAwesomeIcon icon={icon({name: 'check'})} /></td> : <td><FontAwesomeIcon icon={icon({name: 'cross'})} /></td>}
                            <td><FontAwesomeIcon onClick={() => console.log('open modal')} icon={icon({name: 'pen-to-square'})} /></td>
                        </tr>)
                    })}
                </tbody>
            </table>
            </div>
            <div onClick={() => console.log('open modal')} style={{borderRadius: '10px', width: '40%', justifyContent: 'center', backgroundColor: '#5171ff', marginTop: '10px', display: 'flex', paddingTop: '10px', paddingBottom: '10px'}}><FontAwesomeIcon icon={icon({name: 'add'})} /></div>
        </div>
        <p onClick={() => {sessionStorage.removeItem('authenticated'); sessionStorage.removeItem('user'); setRerender(!rerender)}} className="logoutbtn"><FontAwesomeIcon style={{marginRight: '10px'}} icon={icon({name: 'arrow-left'})} />Log uit</p>
      {/* <button onClick={() => {sessionStorage.removeItem('authenticated'); sessionStorage.removeItem('user'); setRerender(!rerender)}}>Log uit</button> */}
      </div>
      </div>
    )


}

export default Admin;