import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./fonts/Demo_Fonts/Fontspring-DEMO-pontiac_black.otf";
import { usePromiseTracker } from "react-promise-tracker";

const LoadingIndicator = props => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && 
    <div style={{top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', width: '100vw', height: '100vh', backgroundColor: 'black', opacity: '0.8'}}>
      <span class="loader" style={{width: '50px', height: '50px', border: '5px solid #FFF', borderBottomColor: 'transparent', borderRadius: '50%', display: 'inline-block', boxSizing: 'border-box', animation: 'rotation 1s linear infinite'}}></span>
    </div>
  );  
  }

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
    <LoadingIndicator />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
