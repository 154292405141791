import React, { useCallback, useEffect, useRef, useState } from "react";
import WebCam from "react-webcam";
import logo from "../logo-clean.svg"
import { useNavigate } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import { useIdleTimer } from "react-idle-timer";

const PhotoAlbum = () => {
    const [isVerified, setIsVerified] = useState(false);
    const [imageUrls, setImagesUrls] = useState([]);
    const [loadInit, setLoadInit] = useState(true);
    const [event, setEvent] = useState('');
    const [state, setState] = useState('Active');
    const [count, setCount] = useState(0);
    const [remaining, setRemaining] = useState(0);
    const [passwordNeeded, setPasswordNeeded] = useState(true);

    const onIdle = () => {
        sessionStorage.removeItem('authenticated');
        sessionStorage.removeItem('user');

        if (passwordNeeded) {setIsVerified(false)};
        setState('Idle');
    };

    const onActive = () => {
        setState('Active')
    };

    const onAction = () => {
        setCount(count + 1);
    }

    const { getRemainingTime } = useIdleTimer({
        onIdle,
        onActive,
        onAction,
        timeout: 300_000,
        throttle: 500
    });

    useEffect(() => {
        const eventId = localStorage.getItem('eventId');
        if (!eventId) {
            alert('Er is geen geldig evenement voor u gevonden');
            navigate('/home');
        }
    }, []);

    useEffect(() => {
      const interval = setInterval(() => {
        setRemaining(Math.ceil(getRemainingTime() / 1000))
      }, 500)

      return () => {
        clearInterval(interval)
      }
    })

    const checkIfPassNeeded = async () => {
        const response = await trackPromise(fetch(`https://api.jannekedegraaff.nl/api/snapshot/eventPassNeeded/${localStorage.getItem('eventId')}`)
        .then((r) => r.json())
        .then((r) => {
            if (r[0] && r[0].event_name) {
                setEvent(r[0].event_name);

                if (r[0] && r[0].event_hasPassword === 0) {
                    setPasswordNeeded(false);
                    setIsVerified(true);
                }
            }
            setLoadInit(false);
        })
        )
    }

    useEffect(() => {
        checkIfPassNeeded();
    }, [])


    const checkPw = async () => {
        // gets the current input value
        const answer = document.getElementById("password").value;
        setLoadInit(true);

        const response = await fetch('https://api.jannekedegraaff.nl/api/snapshot/eventAccess/', {
            method: "POST",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({eventId: localStorage.getItem('eventId'), password: answer}),
        })
        .then((r) => r.json())
        .then((r) => {
            if (r[0] && r[0].event_name) {
                setIsVerified(true);
            } else {
                alert("Sorry, that's not it");
            }
            setLoadInit(false);
        });

    };

    const navigate = useNavigate();

    const getAllImagePaths = async() => {
        const imgList = [];
        //production
        const response = await fetch(`https://api.jannekedegraaff.nl/api/snapshot/getImgs/${localStorage.getItem('eventId')}`)
        //development
        // const response = await fetch('http://localhost:3030/api/getImgs/')
        .then((r) => r.json())
        .then((r) => {
            r.map((item, i ) => {
               imgList.push(item);
            })
            imgList.reverse();
            setImagesUrls(imgList);
        })
    }

    useEffect(() => {
        if (isVerified) {
            getAllImagePaths();
        }
    }, [isVerified])

    if (loadInit) {
        return(
            <div className="spinnerbg">
                <div className="spinner"></div>
            </div>
        )
    }

    return(
        <div className="App-background">
            <header className="App-header">
            {/* <img src={logo} className="App-logo" alt="logo" /> */}
            <img onClick={() => navigate('/home')} style={{paddingTop: '5em'}} src={logo} alt="logo"/>
            <h2>{event}</h2>
            </header>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                {isVerified ?
                    <div style={{display: 'flex', flexDirection: "column", alignItems: 'center'}}>
                    {imageUrls.length > 0 ? imageUrls.map((item, key) => {
                        return <><img key={key} style={{width: '90vw', marginTop: '20px', marginLeft: '20px', marginRight: '20px'}} src={`https://jannekedegraaff.nl/img/${localStorage.getItem('eventId')}/${item.url}.png`} alt={item.url}></img> <p style={{marginTop: 0}}>{item.description}</p></>}) : <p>Er zijn nog geen afbeeldingen in dit album</p>}

                    <button style={{marginTop: '20px', marginBottom: '20px'}} onClick={() => {navigate('/home')}}>Terug naar home</button>
                    </div>
                    :
                    <form onSubmit={checkPw} style={{display: 'flex', flexDirection: 'column'}}>
                        <p style={{margin: 0}}>Voer het wachtwoord in</p>
                        <input style={{marginTop: 0, padding: '10px', fontSize: '16px'}} type="password" id="password" name="password" autoComplete="on" />
                        <button>Open album</button>
                    </form>
                }
            </div>
        </div>
    )


}

export default PhotoAlbum;