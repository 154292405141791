import React, { useEffect, useState } from "react";
import logo from "../logo-clean.svg"
import { useNavigate } from "react-router-dom";

const Login = () => {

    const navigate = useNavigate();
    const [wrongCreds, setWrongCreds] = useState(false);

    useEffect(() => {
        if (sessionStorage.getItem('authenticated')) {navigate('/admin')}
    }, [])



    const handleLogin = (e) => {
        e.preventDefault();
        const username = document.getElementById("username").value;
        const password = document.getElementById("password").value;

        const response = fetch('https://api.jannekedegraaff.nl/api/snapshot/login/', {
            method: "POST",
            headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({username, password})
        })
        .then((r) => r.json())
        .then((r) => {
            sessionStorage.setItem('authenticated', true);
            sessionStorage.setItem('user', JSON.stringify(r[0]));
            navigate('/admin')
        })
        .catch((err) => setWrongCreds(true))

    }

    return(
        <div className="App-background">
        <header className="App-header">
        <img onClick={() => navigate('/home')} style={{paddingTop: '5em'}} src={logo} alt="logo"/>
      </header>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <form style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '260px'}} onSubmit={(e) => handleLogin(e)}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <label htmlFor="username">Username</label>
                <input style={{padding: '10px'}} type="text" name="username" id="username" autoComplete="on"></input>
            </div>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <label htmlFor="password">Password</label>
                <input style={{padding: '10px'}} type="password" name="password" id="password" autoComplete="on"></input>
            </div>
            <button>Log in</button>
            {wrongCreds && <p style={{color: 'red', textAlign: 'center'}}>Onbekende inlog gegevens</p>}
        </form>
      </div>
      </div>
    )


}

export default Login;