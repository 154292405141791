import React, { useCallback, useEffect, useRef, useState } from "react";
import WebCam from "react-webcam";
import logo from "../logo-clean.svg"
import { useNavigate } from "react-router-dom";

const UploadDone = () => {

    const navigate = useNavigate();

    useEffect(() => {
      const eventId = localStorage.getItem('eventId');
      if (!eventId) {
          alert('Er is geen geldig evenement voor u gevonden');
          navigate('/home');
      }
  }, [])

    return(
        <div className="App-background">
        <header className="App-header">
        <img onClick={() => navigate('/home')} style={{paddingTop: '5em'}} src={logo}/>
        <h2 style={{textAlign: 'center'}}>Foto is succesvol opgeslagen</h2>
        <button onClick={() => {navigate('/home')}}>Terug naar Home</button>
      </header>
      <div>
        {/* <div className="grid-container" style={{paddingTop: '30%'}}>
            <div className="grid-item" onClick={() => {navigate("/capture")}}> <i  className="fa-solid fa-camera"></i></div>
            <div className="grid-item"><i className="fa-solid fa-folder"></i></div>
            <div class="grid-item">3</div>
            <div class="grid-item">4</div>
        </div>      */}
      </div>
      </div>
    )


}

export default UploadDone;