import React, { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { trackPromise } from 'react-promise-tracker';
import logo from "../logo-clean.svg";
import browserSignature from 'browser-signature';

const CustomWebcam = () => {
  const signature = browserSignature();
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const navigate = useNavigate();
  const [facingMode, setFacingMode] = useState('user');
  const [queryParameters] = useSearchParams();
  const [addDescription, setAddDescription] = useState(false);

  const retake = () => {
      setImgSrc(null);
    };

  const capture = useCallback(() => {
      const imageSrc = webcamRef.current.getScreenshot();
      setImgSrc(imageSrc);

  }, [webcamRef]);

  useEffect(() => {
    console.log("Current Browser Unique Signature: ", signature);
    if (!localStorage.getItem('eventId')) {
      alert('Er is geen geldig evenement voor u gevonden');
      navigate('/home');
    }
  }, [])

  const uploadImage = async (e) => {
    e.preventDefault();
    if (imgSrc) {
      const imgUrl = imgSrc;
      const description = document.getElementById("description").value;

      // const response = await trackPromise(fetch('http://localhost:3030/api/setImg/', {
        const response = await trackPromise(fetch('https://api.jannekedegraaff.nl/api/snapshot/setImg/', {
        method: "POST",
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({image: imgUrl, description: description, eventId: localStorage.getItem('eventId'), signature: signature}),
      })
      .then(() => navigate("/upload-done"))
      .catch(err => alert(err))
      )
    }

  }

  if (imgSrc && addDescription) {
    return (
    <div className="App-background">
      <header className="App-header">
        <img onClick={() => navigate('/home')} style={{paddingTop: '5em'}} src={logo}/>
        <form onSubmit={(e) => uploadImage(e)} style={{display: 'flex', flexDirection: 'column'}}>
            <p style={{margin: 0}}>Voeg een omschrijving toe</p>
            <textarea rows="4" style={{marginTop: '5px', padding: '10px', fontSize: '16px'}} id="description" name="description" />
            <button>Verstuur foto</button>
        </form>
        <p onClick={() => setAddDescription(false)}>Terug</p>
      </header>
    </div>
    )
  }


  return (
      <header className="App-background" style={{backgroundColor: 'black'}}>
      <div className="container" style={{display: 'flex', flexDirection: "column"}}>
        {imgSrc ? (
          <img src={imgSrc} alt="webcam" />
          // <p>{imgSrc}</p>
        ) : (
          <Webcam
          screenshotFormat="image/jpeg"
          mirrored={facingMode === 'user'}
          screenshotQuality={1}
          videoConstraints={{facingMode: facingMode}}
          height="100%"
          width="100%"
          ref={webcamRef} />
        )}
        <div className="btn-container" style={{width:' 100%', display: 'flex', justifyContent: 'center'}}>
          {imgSrc ? (
            <div style={{position: 'fixed', bottom: "0", width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', minHeight: '80px', backgroundColor: '#181818'}}>
              {/* <i  class="fa-solid fa-camera-rotate fa-3x"></i> */}
              {/* <button onClick={retake}>Opnieuw</button> */}
              <a
                className="App-link"
                style={{textDecoration: 'none', color: 'white', paddingLeft: '25px'}}
                onClick={retake}
              //   target="_blank"
                rel="noopener noreferrer"
              >Opnieuw</a>
              <a
                className="App-link"
                style={{textDecoration: 'none', color: 'white', paddingRight: '25px'}}
                // href="/"
              //   target="_blank"
                onClick={() => setAddDescription(true)}
                rel="noopener noreferrer"
              >Gebruik Foto</a>
              {/* <button onClick={usePhoto}>Gebruik foto</button> */}
            </div>
          ) :
          (
            <div style={{position: 'fixed', opacity: '0.8', bottom: 0, width: '100%', padding: '2em', display: 'flex', justifyContent: 'space-between', alignItems: 'center', minHeight: '80px', backgroundColor: '#181818'}}>
              <FontAwesomeIcon onClick={() => {navigate('/home')}} style={{paddingLeft: '2em'}} icon={icon({name: 'arrow-left-long'})} size='xl' />
              {/* <i onClick={() => {navigate('/home')}} style={{paddingLeft: '2em'}} className="fa-solid fa-arrow-left-long fa-xl"></i> */}
              <button style={{bottom: 0, width: '80px', height: '80px', borderRadius: '50%', display: 'flex', backgroundColor:"white", }} onClick={capture}></button>
              <FontAwesomeIcon onClick={() => {facingMode === 'user' ? setFacingMode('environment') : setFacingMode('user')}} style={{paddingRight: '2em'}} icon={icon({name: 'arrows-rotate'})} size='xl' />
              {/* <i onClick={() => {facingMode === 'user' ? setFacingMode('environment') : setFacingMode('user')}} style={{paddingRight: '2em'}} className="fa-solid fa-arrows-rotate fa-xl"></i> */}
            </div>
          )}
        </div>
      </div>
  </header>
  )
}

export default CustomWebcam;