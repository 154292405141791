import './App.css';
import React, { useEffect, useState } from 'react';
import 'react-html5-camera-photo/build/css/index.css';
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Home from './pages/Home';
import CustomWebcam from './pages/WebCam';
import UploadDone from './pages/UploadDone';
import PhotoAlbum from './pages/PhotoAlbum';
import Login from './pages/Login';
import Admin from './pages/Admin';
import UploadPhoto from './pages/UploadPhoto';

function App() {

  return (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Home />}/>
            <Route path="/home" element={<Home />}/>
            <Route path="/capture" element={<CustomWebcam />} />
            <Route path='/upload-done' element={<UploadDone />} />
            <Route path='/photoalbum' element={<PhotoAlbum />} />
            <Route path='/admin' element={<Admin />} />
            <Route path='/login' element={<Login />} />
            <Route path='/upload-photo' element={<UploadPhoto />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
