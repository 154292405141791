import React, { useCallback, useEffect, useRef, useState } from "react";
import WebCam from "react-webcam";
import logo from "../logo.svg";
import { useNavigate, useSearchParams, } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import UploadDone from "./UploadDone";
import UploadPhoto from "./UploadPhoto";

const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    console.log('file', file);
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

const Home = () => {

    const navigate = useNavigate();
    const [queryParameters] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [event, setEvent] = useState('')
    const [eventId, setEventId] = useState(false);
    const [source, setSource] = useState();

    useEffect(() => {
      if (queryParameters.get('eventId')) {
        localStorage.setItem('eventId', queryParameters.get('eventId'));
        setEventId(queryParameters.get('eventId'));
      }
      checkIfPassNeeded();
    }, [])


    useEffect(() => {
      const  setData = async () => {
        const event = localStorage.getItem('eventId');

        if (source &&  event) {

          const imgSrc = await convertBase64(source).then(r => {console.log('response', r); return r});
          console.log('src', imgSrc);
          localStorage.setItem('imgSrc', imgSrc);
          navigate('/upload-photo');
        }
      }

      setData();

    }, [source]);

    const checkIfPassNeeded = async () => {
      const response = await fetch(`https://api.jannekedegraaff.nl/api/snapshot/eventPassNeeded/${localStorage.getItem('eventId')}`)
      .then((r) => r.json())
      .then((r) => {
          if (r[0] && r[0].event_name) {
              setEvent(r[0].event_name);
          }
          setLoading(false);
      })
  }

    return(
        <div className="App-background">

          <header className="App-header">
            {/* <img src={logo} className="App-logo" alt="logo" /> */}
            <img  onClick={() => navigate('/home')} style={{paddingTop: '5em'}} src={logo} alt="logo"/>
            <h4 style={{textAlign: 'center'}}>{event ? `${event}` : 'Er is geen album gevonden, scan de QR code opnieuw of vraag de beheerder naar de link.'}</h4>
            {/* <a
              className="App-link"
              href="/capture"
              rel="noopener noreferrer"
              style={{textDecoration: "none", display: "flex", flexDirection: "column"}}
            >
                <img src={logo}/>
            <i  className="fa-solid fa-camera fa-10x"></i> */}
            <div></div>
            {/* <p>Capture the moment <i className="fa-solid fa-arrow-right"></i></p> */}
            {/* </a> */}
          </header>
          {localStorage.getItem('eventId') &&
            <div>


                <div className="grid-container" style={{paddingTop: '4em'}}>
                    {/* <div className="grid-item" onClick={() => {navigate(`/capture`)}}> */}
                    <label for="file-upload" className="grid-item" >
                      <FontAwesomeIcon icon={icon({name: 'camera'})} />
                      <input id="file-upload" type="file" accept="image/*" capture="user" style={{display: 'none'}} onChange={(e) => setSource(e.target.files[0])}></input>
                      {/* <i  className="fa fa-solid fa-camera"></i> */}
                      </label>
                    <div className="grid-item" onClick={() => {navigate("/photoalbum")}}>
                      <FontAwesomeIcon icon={icon({name: 'folder'})} />
                      {/* <i className="fa fa-solid fa-folder"></i> */}
                      </div>
                    {/* <div class="grid-item">3</div>   */}
                    {/* <div class="grid-item">4</div> */}
                </div>

            </div>

          }
      </div>

    )


}

export default Home;