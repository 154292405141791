import React from "react";


const EventModal = ({props}) => {
    // const show = props.show;
    // const onClose = props.onClose;
    const type = 'add'

    return(
            <div className="modal">
                {/* <div className="modal-content"> */}
                    {/* <h2>Event {type === 'add' ? 'aanmaken' : 'veranderen'}</h2> */}

                {/* </div> */}

            </div>
    )

}

export default EventModal